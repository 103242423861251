import "./assets/styles/status.scss";
import "./assets/styles/custom-ant.scss";
import "./assets/styles/main.css";
import "./assets/styles/loading.css";
import "./assets/styles/responsive.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/layout/Main";
import React, { lazy } from "react";
import { ListRoutes } from "./routes";
import { ThemeProvider, createTheme } from "@mui/material";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const OrderList = lazy(() => import("./pages/OrderList"));
// const OrderDetail = lazy(() => import("./pages/OrderDetail/OldOrderDetail"));
const OrderDetailNew = lazy(() => import("./pages/OrderDetail"));
const CreateOrder = lazy(() => import("./pages/CreateOrder"));

function App() {
  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: '"Barlow", "sans-serif"',
        },
        palette: {
          primary: { main: "#1890ff" },
          text: { primary: "#141414", secondary: "#6B6B6B" },
          info: { main: "#007ad2" },
          error: { main: "#F00202" },
        },
      }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* route login */}
          <Route path={ListRoutes.LOGIN} element={<Login />} />

          {/* route author */}
          <Route path={ListRoutes.DEFAULT} element={<Main />}>
            <Route path={ListRoutes.DASHBOARD} element={<Dashboard />} />
            <Route path={ListRoutes.ORDERS} element={<OrderList />} />
            <Route path={ListRoutes.CREATE_ORDER} element={<CreateOrder />} />
            {/* <Route
              path={`${ListRoutes.ORDER_DETAIL}/:id`}
              element={<OrderDetail />}
            /> */}
            <Route
              path={`${ListRoutes.ORDER_DETAIL_NEW}/:id`}
              element={<OrderDetailNew />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
